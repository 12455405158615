import React from 'react';

const Releases = ({ embeds }) => {
    const releaseList = embeds.map(embed => {
        return (
            <div className="content-entry" key={embed.id}>
                <span>{embed.n.toLowerCase()}</span><br></br>
                <span className="artist-name">{embed.ar.toLowerCase()}, {embed.y}</span>
            </div>
        )
    });
    return (
        <div className="catalog-container container longForm">{releaseList}</div>
    )
}

export default Releases