import React from 'react'

const Footer = () => {
    var aStyle = { "color": "gray" }
    return (
        <div className="footer">
            <span>ambient music for work and reflection</span><br></br><span className="footer-small">vaporwave icon by <a rel="noreferrer" target="_blank" href="https://icons8.com/icon/75cx4Cot4KnV/vaporwave" style={aStyle}>icons8</a></span>
        </div>
    )
}

export default Footer